import React from 'react'
import logo from '../assets/images/logo.png'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
              <div className="grid-wrapper">
              <div className="inner">
                <div className="transparent-card">
                  <span className="major"><img src={logo} alt="The Water Store Utah" /></span>
                  <h1>You can taste the difference, Drink Up!</h1>  
                    <p><strong>The Water Store in Sandy, Utah</strong><br />Purified Drinking Water, Bottles, and Ice.</p>
                    <p>Call us: 801-255-0606 or come on in:<br />7822 South 700 East Sandy, Utah.</p>
                  </div>
                  <ul className="actions">
                      <li><a href="#one" className="button scrolly">Drink Up</a></li>
                  </ul>
              </div>
              </div>
            </section>
        )
    }
}

export default Header
