import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import storefront from '../assets/images/storefront.jpg'
import bottlerack from '../assets/images/bottlerack.jpg'
import outsidedispenser from '../assets/images/outside-dispenser.jpg'
import outsidedispenser2 from '../assets/images/outside-dispenser-2.jpg'
import filling from '../assets/images/fillingbottles.jpg'
import waterdispensers from '../assets/images/waterdispensers.jpg'
import pureice from '../assets/images/pureice.jpg'

class Homepage extends React.Component {
    render() {
        const siteTitle = "The Water Store";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <span className="image fit"><img src={storefront} alt="Refill your bottles with purified water" /></span>
                        </div>
                        <div className="col-6">
                          <header className="major">
                            <h2>How it Works</h2>
                          </header>
                            <p>Bring in your empty, refillable, water bottles and we'll fill them up with clean, purified water. After, we'll carry out your bottles and help you load them in your car.</p>
                            <p>Pay as you go, or purchase a water card to use any time.</p>
                            <p>Can't make it in during business hours? We have a water filling station outside.</p>
                        </div>
                    </div>

                </section>

                <section id="photos" className="main product-photos style2">
                <div className="grid-wrapper">
                  <div className="col-4">
                  <span className="image fit"><img src={bottlerack} alt="We carry all sizes of refillable bottles" /></span>
                  <span className="image fit"><img src={waterdispensers} alt="Buy your next water cooler or dispenser here" /></span>

                  </div>
                  <div className="col-4">
                    <span className="image fit"><img src={filling} alt="Refill 5 gallon water bottles" /></span>
                  </div>
                  <div className="col-4">
                    <span className="image fit"><img src={pureice} alt="We sell bagged ice made from purified water" /></span>
                    <span className="image fit"><img src={outsidedispenser2} alt="After hours water filling station" /></span>
                  </div>
                </div>
                </section>

                <section id="photos" className="main products">
                <div className="grid-wrapper">
                  <div className="col-6">

                    <header className="major">
                        <h2>Products We Carry</h2>
                    </header>
                    <ul>
                      <li>5 Gallon Bottles in Cap or Threaded Top</li>
                      <li>1, 2, and 3 Gallon Bottles in various sizes and types</li>
                      <li>Small Water Bottles</li>
                      <li>Table Top and Free Standing Water Dispensers</li>
                      <li>Water Coolers for the Home or Office</li>
                      <li>Purified Bagged Ice</li>
                      <li>and more...</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <span className="image fit"><img src={bottlerack} alt="Various bottle sizes for sale" /></span>
                  </div>
                </div>
                </section>



                <section id="two" className="main style2 purification">
                    <div className="grid-wrapper">
                        <div className="col-12">
                        <header className="major">
                            <h2>How our Purification System Works</h2>
                        </header>
                        <h3>8-Stage Purification System</h3>
                        <p>Most reverse osmosis units waste between three to five gallons of purified water per one gallon produced. Our system has been designed to be environmentally friendly; therefore, we only waste about 1/3 of a gallon for every gallon of purified water attained. Throughout our system we have installed check valves and back flow protection valves to prevent the municipal water from contaminating any of the purified water.&nbsp;</p>
                        </div>
                    </div>
                    <div className="grid-wrapper">
                      <div className="col-6">
                        <h4>1: Ion Exchange</h4>
                        <p>The Ion Exchange removes any metals, radiation, and corrosive scale. Eliminating these contaminates not only is the first step to clean water but helps to prolong RO membrane life.</p>
                        <h4>2: Granular Activated Carbon Filter</h4>
                        <p>The GAC Filter removes chemicals, such as chlorine and lead obtained from corroded pipes in our water systems.</p>
                        <h4>3: Pre-Sediment-Filter</h4>
                        <p>This 1 micron sediment filter removes all sediment from the water, such as loose scale and organic materials.</p>
                        <h4>4: Reverse Osmosis Membrane Filtration</h4>
                        <p>The membrane of the RO unit removes salts and other impurities from the municipal water. The purified water is then sent to an FDA approved polyethylene holding tank. The impurities from the water and some waste water are disposed of. However, our system recycles some of this waste water through the RO unit again. To help save on water consumption and obtain the most purified water possible from the least amount of municipal, ‘tap’ water.</p>
                    </div>
                      <div className="col-6">
                        <h4>5: Ozone Generator</h4>
                        <p>Ozone is the fastest and most thorough disinfectant available for water, while leaving no harmful residue or chemicals in the water. Ozone is 3200 times more effective than chlorine, yet reverts back to oxygen within a few hours. And Ozone is completely safe to drink! At this point, the Ozone is injected into the water as it passes through a venturi.</p>
                        <h4>6: Ozone Contact Tank</h4>
                        <p>This allows for a through mixing of the Ozone and water. Key: The smaller the bubbles, the more useful the Ozone is in disinfecting. The injection of the Ozone in the holding tanks is administered at scheduled times throughout the day. This process never allows our water to sit for long periods of time which is why it always tastes clean, fresh.</p>
                        <h4>7: Post GAC Filtration</h4>
                        <p>The post carbon filtration takes place in order to ‘polish the water’. This stage catches anything that may have been missed as well as removing any tastes and/or odors the water that may have collected from the equipment and\or initial water supply.</p>
                        <h4>8: Ultraviolet Disinfections</h4>
                        <p>The ultraviolet treatment is a secondary disinfectant that aids in eliminating any biological contaminates such as giardia and cryptosporidium.</p>
                      </div>

                    </div>
                </section>


                <section id="four" className="main style1 special">
                    <div className="container">
                        <header className="major">
                            <h2>Location and Hours</h2>
                        </header>

                    </div>
                    <div className="grid-wrapper">
                    <div className="col-6">
                      <p>We are located at<br /><strong>7822 South 700 East Sandy, Utah</strong><br />801-255-0606</p>
                      <h4>Store Hours:</h4>
                      {/* <p><strong>Mon - Fri:</strong> 10:00am - 7:00pm<br /><strong>Saturday:</strong> 10:00am - 6:00pm<br /><strong>Sunday:</strong> Closed</p> */}

                      <p><strong>Mon - Fri:</strong> 10:00am - 5:00pm<br /><strong>Saturday:</strong> 10:00am - 5:00pm<br /><strong>Sunday:</strong> Closed</p>
                    </div>
                    <div className="col-6">
                      <iframe frameborder="0" height="350" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?q=Water+Store&amp;ie=UTF8&amp;hq=&amp;hnear=7822+S+700+E,+Sandy,+Utah+84070&amp;gl=us&amp;t=m&amp;cid=5058563277152312620&amp;ll=40.609448,-111.872202&amp;spn=0.001425,0.00228&amp;z=18&amp;iwloc=A&amp;output=embed" width="100%"></iframe>
                    </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;
